import classes from "./subscription.module.css";
import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Jobs from "../../../../components/JobSubscription/jobs";
import { subscriptions } from "../../../../apis";
import { errorHandler } from "../../../../utils";
import UpdateSubscription from "../Update/updateSubscription";

const Subscription = () => {
  const [state, setState] = useState("active");
  const [editMode, setEditMode] = useState(false);
  const [subArr, setSubArr] = useState([]);
  const [jobsData, setJobsData] = useState(null);
  const [page, setPage] = useState(1);
  const [subData, setSubData] = useState({});
  const [totalItems, setTotalItems] = useState(null);
  const [param] = useSearchParams();

  

  const getSubscriptionHistory = async (page) => {
    try {
      const res = await subscriptions(page);
      if (page !== res.data.counts) {
        setJobsData(res.data);
        setTotalItems(res.data.totalItems);
      }
      if (res?.data.records) {
        setSubArr(res.data.records);
      }
    } catch (error) {
      error.response?.data
        ? errorHandler(error.response.data.errors, "Error")
        : errorHandler(
            "Network error: Something went wrong, Please check your internet connection and try again.",
            "Error"
          );
    }
  };
  useEffect(() => {
    getSubscriptionHistory();
  }, []);

  useEffect(() => {
    if (param.get("edit") === "active") {
      setEditMode(true);
    }
  }, [param]);

  return (
    <div className={classes.container}>
      <h1>Subscriptions</h1>

      <div className={classes.header}>
        <ul className="flex mb-md">
          <li
            className={state === "active" ? classes.active : classes.inactive}
            onClick={() => setState("active")}
          >
            <span>Active</span>
          </li>
          <li
            className={state === "archived" ? classes.active : classes.inactive}
            onClick={() => setState("archived")}
          >
            <span>Archived</span>
          </li>
          <li
            className={
              state === "completed" ? classes.active : classes.inactive
            }
            onClick={() => setState("completed")}
          >
            <span>Completed</span>
          </li>
        </ul>
      </div>

      <div>
        {editMode ? (
          <UpdateSubscription data={subData} />
        ) : (
          <div className={classes.card}>
            <Jobs subArr={subArr} setSubData={setSubData}/>
          </div>
        )}
      </div>
    </div>
  );
};

export default Subscription;
