import classes from "./jobs.module.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Sort } from "../../assets/images/icons/sort";
import moment from "moment";
import Edit from "../../assets/images/icons/edit.svg";
import Delete from "../../assets/images/icons/delete.svg";
import Archive from "../../assets/images/icons/archive.svg";

const Jobs = ({ subArr = [], setSubData }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className={`mb-sm ${classes.categoryFilters}`}>
        <div className={classes.sort}>
          <p>NAME</p>
          <Sort />
        </div>

        <div className={`${classes.address} ${classes.sort}`}>
          <p>LOCATION</p>
          <Sort />
        </div>

        <div className={`${classes.address} ${classes.sort}`}>
          <p>JOB TYPE</p>
        </div>

        <div className={`${classes.address} ${classes.sort}`}>
          <p>LAST UPDATED</p>
          <Sort />
        </div>

        <div className={classes.sort}>
          <p>AMOUNT</p>
          <Sort />
        </div>

        <div className={classes.sort}>
          <p>PLAN</p>
          <Sort />
        </div>

        <div className={classes.sort}>
          <p>ACTIONS</p>
          <Sort />
        </div>
      </div>
      <div className={classes.scroll}>
        {subArr.map((item, idx) => (
          <Link
            className={`mb-sm ${classes.parent}`}
            key={idx}
            to="?edit=active"
          >
            <div>
              <h3>{item.name}</h3>
            </div>
            <div>
              <p>-</p>
            </div>
            <p>Job</p>
            <p>{moment(item?.updatedAt).format("DD-MMM-yyyy")}</p>
            <p>{item?.price}</p>
            <p>Matching</p>
            <div className={classes.actionGroup}>
              <img
                src={Edit}
                alt=""
                onClick={() => {
                  navigate(`?edit=active`)
                  setSubData(item)
                }}
              />
              <img src={Delete} alt="" />
              <img src={Archive} al/>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Jobs;
