import classes from "./updateSubscription.module.css";
import { useEffect, useState } from "react";
import RadioInput from "../../../../components/UI/RadioInput/input";
import Button from "../../../../components/UI/Button/Button";

const UpdateSubscription = ({ data }) => {
  const [state, setState] = useState("active");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className={""}>
      <div>
        <form onSubmit={handleSubmit} className={classes.container}>
          <div className={classes["form-group"]}>
            <label>
              Name*
              <input
                required
                type="text"
                placeholder=""
                className="mt-sm"
                value={data?.name}
                readOnly
              />
            </label>
            <label>
              Location
              <input
                required
                type="text"
                placeholder="Location"
                className="mt-sm"
                value={data?.location}
                readOnly
              />
            </label>
          </div>

          <div className={classes["form-group"]}>
            <div className={classes.radios}>
              <p>Status</p>
              <div>
                <RadioInput
                  text="Active"
                  value="Active"
                  name="status"
                  checked
                />

                <RadioInput text="Inactive" value="Inactive" name="status" />
              </div>
            </div>
            <label>
              Amount
              <input
                required
                type="text"
                placeholder=""
                className="mt-sm"
                value={data?.price}
                disabled
              />
            </label>
          </div>

          <div className={classes["form-group"]}>
            <div className={classes.radios}>
              <p>Job Type</p>
              <div>
                <RadioInput
                  text="Long Term"
                  value="LongTerm"
                  name="JobType"
                  checked
                />

                <RadioInput
                  text="Short term / Gig"
                  value="ShortTerm"
                  name="JobType"
                />
              </div>
            </div>
          </div>
          <div className={classes["form-group"]}>
            <div className={classes.radios}>
              <p>Subscription Plan</p>
              <div>
                <RadioInput
                  text="Standard One-off"
                  value="Standard"
                  name="SubscriptionPlan"
                  checked
                />

                <RadioInput
                  text="Matching Fee"
                  value="MatchingFee"
                  name="SubscriptionPlan"
                />
              </div>
            </div>
          </div>
          <div className={classes["form-group"]}>
            <div className={classes.radios}>
              <p>Retainer</p>
              <div>
                <RadioInput text="Yes" value="Yes" name="Retainer" checked />

                <RadioInput text="No" value="No" name="Retainer" />
              </div>
            </div>
          </div>
          <div className={classes["form-group"]}>
            <label>
              Date
              <input
                required
                type="date"
                placeholder="DD/MM/YYYY"
                className="mt-sm"
                disabled
              />
            </label>
            <label>
              Last Updated
              <input
                required
                type="text"
                placeholder="DD/MM/YYYY"
                className="mt-sm"
                disabled
              />
            </label>
          </div>

          <div className={classes.btn}>
              <Button variant="white" isLink to="/dashboard/users/talents">
                Cancel
              </Button>
              <Button type="submit" variant="blue">Submit</Button>
            </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateSubscription;
