import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/UI/Button/Button";
import classes from "./ForgotPassword.module.css";
import { endAuthLoading, forgotPassword, } from "../../../redux/actions/authActions";
import { useDispatch, useSelector } from "../../../redux/store";

const AdminForgotPassword = () => {
  const { isLoading, isAuthenticated } = useSelector(store => store.auth);
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    email: "",
  });

  const dispatch = useDispatch()

  const handleChange = (e) =>
    setPayload({ ...payload, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(forgotPassword(payload));
  };

  useEffect(() => {
    if (!isAuthenticated && isLoading) {
      dispatch(endAuthLoading());
    }
  }, []);

  return (
    <section className={classes.login}>
      <h2 className="mt-sm mb-10 font-semibold text-xl md:text-2xl">FORGOT PASSWORD</h2>
      <form className="t-md" onSubmit={handleSubmit}>
        <label htmlFor="email">
          Email Address
          <input
            type="email"
            name="email"
            placeholder="admin@awasource.com"
            id="email"
            value={payload.email}
            onChange={handleChange}
            required
          />
        </label>

        <div className="mt-md flex justify-center items-center gap-5">
          <Button variant="white" onClick={() => {
            navigate("/")
          }}>Cancel</Button>

          <Button type="submit" variant="yellow" disabled={isLoading}>{isLoading ? 'Submitting...' : 'Forgot Password'}</Button>
        </div>
      </form>
    </section>
  );
};
export default AdminForgotPassword;
