import { useEffect, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import classes from "./SelfDevelopment.module.css";
import Plus from "../../../assets/images/icons/plus.svg";
import Play from "../../../assets/images/icons/play.svg";
import Delete from "../../../assets/images/icons/delete-filled.svg";
import Tick from "../../../assets/images/icons/tick.svg";
import { errorHandler } from "../../../utils";
import { addSelfDevelopment, getAllSelfDevelopmentsCategories, getAllSelfDevelopmentsCategory } from "../../../apis";
import ReactPlayer from "react-player";

const SelfDevelopment = () => {
  const [categories, setCategories] = useState([]);
  const [allSelfDev, setAllSelfDev] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [payload, setPayload] = useState({
    url: "",
    title: "",
    type: "Video",
    status: "Active",
    category: "",
  });
  const handleChange = (e) =>
    setPayload({ ...payload, [e.target.name]: e.target.value });

    const handleGetAllSelfDevelopmentsCategories = async (page) => {
      try {
        const res = await getAllSelfDevelopmentsCategories();
        if (res?.data) {
          setCategories(res.data);
        }
      } catch (error) {
        errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
      }
    };

    const handleGetAllSelfDevelopmentsCategory = async (page) => {
      try {
        const res = await getAllSelfDevelopmentsCategory();
        if (res?.data.records) {
          setAllSelfDev(res.data.records);
        }
      } catch (error) {
        errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
      }
    };

    useEffect(() => {
      handleGetAllSelfDevelopmentsCategories();
      handleGetAllSelfDevelopmentsCategory();
    }, []);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setSubmitting(true);
      try {
        const res = await addSelfDevelopment(payload);
        setSubmitting(false);
        if (res?.data) {
          handleGetAllSelfDevelopmentsCategory();
          setPayload({
            url: "",
            title: "",
            type: "Video",
            status: "Active",
            category: "",
          });
        }
        
      } catch (error) {
        setSubmitting(false);
        errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
      }
    };

  return (
    <div>
      <div className="flex flex-col md:flex-row gap-3 md:items-center justify-between">
        <h2>Self Development controls</h2>
        <div className={classes.container}>
          <select name="category" onChange={handleChange} className="w-[458px] max-w-full">
            <option>Select Category</option>
            {categories?.map((category, idx) => (
              <option key={idx}>{category}</option>
            ))}
          </select>
        </div>
      </div>

      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={`${classes.container} !flex flex-col md:flex-row gap-3 md:gap-4 items-end`}>
          <label className="mr-auto flex-grow md:!mb-0">
            Video Title*
            <input type="text" value={payload.title} onChange={handleChange} name="title" />
          </label>
          <label className="mr-auto flex-grow md:!mb-0">
            Video Link*
            <input type="text" value={payload.url} onChange={handleChange} name="url" />
          </label>

          <Button variant="white" to="#" className="!min-w-[50px] w-[50px] h-[50px]">
            <img src={Delete} alt="Delete" />
          </Button>
          <Button variant="white" to="#" className="!min-w-[50px] w-[50px] h-[50px]">
            <img src={Plus} alt="Plus" />
          </Button>
        </div>

        <div className={classes.btn}>
          <Button variant="white" isLink to="#">
            Cancel
          </Button>
          <Button variant="blue" disabled={submitting} onClick={handleSubmit}>
            {" "}
            {submitting ? "Loading..." : "Submit"}
          </Button>
        </div>
      </form>

      <div className={classes.form}>
        <div className="flex flex-col md:flex-row gap-3 flex-wrap items-center">
          {allSelfDev?.map((item, idx) => (
            <div className={`${classes.containerCard}`} key={idx}>
              <ReactPlayer url={item?.url} width="100%" height="131px" controls />
              <div className={classes["video-title"]}>
                <h3>{item?.title}</h3>
                <p>{item?.category}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelfDevelopment;
