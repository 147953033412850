import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { DASHBOARD_CARDS } from "../../../constants";
import classes from "./Overview.module.css";
import { useDispatch, useSelector } from "../../../redux/store";
import { getActivity, getMetrics } from "../../../redux/actions/dashboardActions";
import { DASHBOARD_JOB_SUMMARY } from "../../../__mock__";
import FullScreenLoader from "../../../components/UI/FullScreenLoader/FullScreenLoader";
import moment from "moment";

const Overview = () => {
  const { dashboard } = useSelector(store => store);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getMetrics());
    dispatch(getActivity());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {dashboard.isLoading && <FullScreenLoader />}
      <h2 className="mb-md">Dashboard</h2>
      <div className={classes.grid}>
        {DASHBOARD_CARDS.map((item, i) => (
          <SummaryCard key={`summary-${i}`} {...item} count={dashboard?.metrics?.[item.name.toLowerCase()] ?? 0} />
        ))}
      </div>
      <Notifications dashboard={dashboard} />
    </div>
  );
};

export default Overview;

const SummaryCard = ({ name, image, count, color }) => (
  <div className={`flex ${classes.summary} ${classes[color]}`}>
    <div>
      <p>{name}</p>
      <hr />
      <p className={`subtitle`}>{count}</p>
    </div>
    <div className={classes.img}>
      <img src={image} alt={name} />
    </div>
  </div>
);

const Notifications = ({ dashboard }) => {
  const dispatch = useDispatch()
  const [summaryHasNextPage, setSummaryHasNextPage] = useState(true);
  const [summaryType, setSummaryType] = useState("activity");
  const summaryList = useMemo(() => ({
    activity: dashboard.activities.activities,
    job: DASHBOARD_JOB_SUMMARY,
  }), [dashboard.activities])

  useEffect(() => {
    setSummaryHasNextPage(((dashboard?.activities?.page || 0)) < (dashboard?.activities?.totalPages))
  }, [dashboard?.activities?.page, dashboard?.activities?.totalPages])

  const handleGetNextRecords = useCallback(() => {
    if ((dashboard?.activities?.page || 0) >= (dashboard?.activities?.totalPages)) return;

    dispatch(getActivity(((dashboard?.activities?.page || 0) + 1)));
  }, [dashboard?.activities?.page, dashboard?.activities?.totalPages, dispatch])

  return (
    <div className={`mt-md ${classes.notification}`} id="activity">
      <div className={`mb-sm ${classes.btn}`}>
        <button
          onClick={() => setSummaryType("activity")}
          className={summaryType === "activity" ? classes.active : ""}
        >
          Activity Summary
        </button>
        <button
          onClick={() => setSummaryType("job")}
          className={summaryType === "job" ? classes.active : ""}
        >
          Job Summary
        </button>
      </div>
      <hr />
      <div className="mt-md">
        {summaryList[summaryType].map((item, i) => (
          <div key={`activity-${i}`} className={`flex ${classes.activity}`}>
            <p>{item.message}</p>
            <p>{moment(item.created_at).fromNow()}</p>
          </div>
        ))}
      </div>
      {(summaryType !== "activity" || summaryHasNextPage) && <Link
        to={summaryType === "activity" ? "#" : "/dashboard/jobs"}
        onClick={summaryType === "activity" ? handleGetNextRecords : undefined}
        className={classes.link}>See all</Link>}
    </div>
  );
};
