import Button from "../../../../../components/UI/Button/Button";
import classes from "./Clients.module.css";
import Edit from "../../../../../assets/images/icons/edit.svg";
import Delete from "../../../../../assets/images/icons/delete.svg";
import Archive from "../../../../../assets/images/icons/archive.svg";
import UnArchive from "../../../../../assets/images/icons/unarchive.svg";
import Mail from "../../../../../assets/images/icons/mail.svg";
import { useDispatch, useSelector } from "../../../../../redux/store";
import { useCallback, useEffect, useState } from "react";
import { getClients } from "../../../../../redux/actions/clientActions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router";
import FullScreenLoader from "../../../../../components/UI/FullScreenLoader/FullScreenLoader";
import { archiveClientApi, deleteClientApi, deleteMultipleClientApi, subscriptions, unArchiveClientApi } from "../../../../../apis";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../../utils/errorHandler";
import ConfirmDialog from "../../../../../components/UI/ConfirmDialog/ConfirmDialog";
import CheckInput from "../../../../../components/UI/CheckInput/input";
import { Link } from "react-router-dom";
import { handleFilterItems } from "../../../../../utils";

const Clients = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isArchiveOpen, setIsArchiveOpen] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [unSelectedItems, setUnSelectedItems] = useState([]);
  const { isLoading, clients } = useSelector(store => store.client);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    sortField: null,
    sortOrder: null,
  });

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleGetAllRecords = useCallback(() => {
    dispatch(getClients(((lazyParams?.page || 0) + 1), lazyParams?.rows));
    setSelectedItem(null);
  }, [dispatch, lazyParams?.page, lazyParams?.rows])

  const handleCheckAll = useCallback((e) => {
    setCheckAll(e.target.checked);
    setUnSelectedItems([])
    setSelectedItems([])
  }, []);

  const handleCheck = useCallback((item) => {
    if ((checkAll || selectedItems.find(s => s._id === item._id))) {
      if (!unSelectedItems.find(s => s._id === item._id)) {
        setUnSelectedItems(prev => [...prev, item]);
      }
      if (selectedItems.find(s => s._id === item._id)) {
        setSelectedItems(prev => prev.filter(p => p._id !== item._id));
      }
    }
    if ((!checkAll || unSelectedItems.find(s => s._id === item._id))) {
      if (unSelectedItems.find(s => s._id === item._id)) {
        setUnSelectedItems(prev => prev.filter(p => p._id !== item._id));
      }
      if (!selectedItems.find(s => s._id === item._id)) {
        setSelectedItems(prev => [...prev, item]);
      }
    }
  }, [checkAll, selectedItems, unSelectedItems]);

  const handleDeleteMultiple = useCallback(async () => {
    setLoading(true)
    try {
      if (checkAll || selectedItems.length > 0) {
        const items = checkAll ? handleFilterItems(clients?.records, unSelectedItems) : selectedItems.map(s => s._id);
        const { data } = await deleteMultipleClientApi(items);
        setIsOpen(false)
        if (data) {
          toast.success(data.message || 'Records deleted successfully');
          handleGetAllRecords();
        }
      } else {
        toast.error("No record selected for this operation")
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [checkAll, clients?.records, handleGetAllRecords, selectedItems, unSelectedItems])

  const handleSelect = useCallback(async (item, isDeleteAction = true) => {
    setSelectedItem(item)
    if (isDeleteAction) {
      setIsOpen(true)
    } else {
      setIsArchiveOpen(true)
    }
  }, [])

  const handleDelete = useCallback(async () => {
    setLoading(true)
    try {
      if (selectedItem) {
        const { data } = await deleteClientApi(selectedItem?._id);
        setIsOpen(false)
        if (data) {
          toast.success(data.message || 'Record deleted successfully');
          handleGetAllRecords();
        }
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [handleGetAllRecords, selectedItem])

  const handleArchive = useCallback(async () => {
    setLoading(true)
    try {
      if (selectedItem) {
        const { data } = await (selectedItem?.isArchived ? unArchiveClientApi(selectedItem?._id) : archiveClientApi(selectedItem?._id));
        setIsArchiveOpen(false)
        if (data) {
          toast.success(data.message || `Record ${selectedItem?.isArchived ? "un" : ""}archived successfully`);
          handleGetAllRecords();
        }
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [handleGetAllRecords, selectedItem])

  useEffect(() => {
    handleGetAllRecords();
  }, [handleGetAllRecords,])

  const onLazyLoad = (event) => {
    setLazyParams(event);
  };

  const fetchJobApplicants = async () => {
    try {
      const res = await subscriptions();

    } catch (error) {
      error.response?.data
        ? errorHandler(error.response.data.errors, "Error")
        : errorHandler("Network error: Something went wrong, Please check your internet connection and try again.", "Error");
    }
  };

  useEffect(() => {
    fetchJobApplicants()
  }, [])

  return (
    <div>
      {(isLoading || loading) && <FullScreenLoader />}

      <ConfirmDialog confirmText="Delete" handleConfirm={selectedItem ? handleDelete : handleDeleteMultiple} setIsOpen={setIsOpen} isOpen={isOpen} desc={`Are you sure you want to delete ${selectedItem ? 'this client' : 'these clients'}?`} />

      <ConfirmDialog confirmText={`${selectedItem?.isArchived ? "Una" : "A"}rchive`} handleConfirm={selectedItem ? handleArchive : null} setIsOpen={setIsArchiveOpen} isOpen={isArchiveOpen} desc={`Are you sure you want to ${selectedItem?.isArchived ? "un" : ""}archive ${selectedItem ? 'this client' : 'these clients'}?`} />

      <h2>Clients</h2>
      <div className={`mt-md ${classes.table}`}>
        <div className={`flex flex-col md:flex-row md:items-center gap-4 mb-md ${classes.btn}`}>
          <div className={`flex items-center ${classes.otherbutton}`}>
            <Button variant="white" disabled={!checkAll && selectedItems.length === 0} onClick={() => navigate(`/dashboard/emails/send`, { state: { recipients: checkAll ? (clients.records || []) : selectedItems, checkAll } })}>Email</Button>
            <Button disabled={!checkAll && selectedItems.length === 0} onClick={() => setIsOpen(true)} variant="white">Delete</Button>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <input placeholder="Search Clients" />
            {/* <Button
              variant="yellow"
            // onClick={() => navigate("/dashboard/add-email")}
            >
              Add Clients
            </Button> */}
          </div>
        </div>

        <DataTable
          value={clients.records || []}
          lazy
          paginator={clients.totalPages > 1}
          rows={lazyParams.rows}
          totalRecords={clients.totalItems}
          loading={isLoading}
          onPage={onLazyLoad}
          first={lazyParams.first}
          emptyMessage="No record found"
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '100%' }}
        >
          <Column className="pr-3 text-center"
            field="_id"
            header={
              <CheckInput type="checkbox" className={`w-[18px] h-[18px] rounded-s`} isIndeterminate={checkAll && unSelectedItems.length > 0} checked={checkAll} onChange={handleCheckAll} />}
            body={(item) => (
              <div className="text-left">
                <CheckInput type="checkbox" className="w-[18px] h-[18px] rounded-s" checked={(checkAll || selectedItems.find(s => s._id === item._id)) && !unSelectedItems.find(s => s._id === item._id)} onChange={() => handleCheck(item)} />
              </div>
            )}
          />
          <Column className="p-5 text-center"
            field="firstName"
            header="FULL NAME"
            body={(item) => (
              <Link to="/dashboard/users/clients/edit" state={item} className="text-left min-w-[150px] flex gap-4 items-center">
                <div className="rounded-full bg-[#F5F6FA] h-[38px] w-[38px] flex items-center justify-center font-semibold">
                  {(item.firstName || item.email)?.charAt(0)?.toUpperCase()}
                </div>
                <div className="flex flex-col gap-1 ">
                  <div className="font-medium">
                    {!item.firstName && !item.lastName ? '- -' : `${item.firstName ?? ''} ${item.lastName ?? ''}`}
                  </div>
                  <small className="text-[#808080]">
                    {item.companyName ?? ''}
                  </small>
                </div>
              </Link>
            )}
            sortable
          />
          <Column className="p-5"
            field="email"
            header="EMAIL"
            body={(item) => (
              <Link to="/dashboard/users/clients/edit" state={item} className={classes.subject}>{item.email}
              </Link>)}
            sortable
          />
          <Column className="p-5"
            field="updatedAt"
            header="LAST UPDATED"
            body={(item) => (
              <div>{item.updatedAt ? new Date(item.updatedAt).toLocaleDateString() : ""}
              </div>)}
            sortable
          />
          <Column className="p-5" header="ACTIONS" body={(item) => (
            <div className="flex items-center gap-2 min-w-[185px]">
              <button className={`relative ${classes.edit}`} onClick={() => navigate(`/dashboard/users/clients/edit`, { state: item })}>
                <img src={Edit} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Edit Client
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button onClick={() => handleSelect(item)} className={`relative ${classes.delete}`}>
                <img src={Delete} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Delete Client
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.archive}`} onClick={() => handleSelect(item, false)}>
                <img src={item?.isArchived ? UnArchive : Archive} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    {item?.isArchived ? "Una" : "A"}rchive Client
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.archive}`} onClick={() => navigate(`/dashboard/emails/send`, { state: { recipients: [item] } })}>
                <img src={Mail} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Email Client
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
            </div>)} />
        </DataTable>
      </div>
    </div>
  );
};

export default Clients;
