export const handleFilterItems = (records = [], unSelectedItems = []) => records?.reduce((acc, talent) => {
    if (!unSelectedItems.includes(talent?._id)) {
        acc.push(talent._id)
    }
    return acc;
}, [])

export const createUserGroupIdentifier = (name = "") => name?.trim()?.toLowerCase()?.split(" ")?.join("_");

export const capitalizeEachWord = (word = "") => word.replace(/\b\w/g, char => char.toUpperCase());
