import { adminRoute, route } from "../base";
import httpService from "../base/httpService";

export const getAdminsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${adminRoute}/admins?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const getUserGroupsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${adminRoute}/admin-user-groups?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const addAdminApi = async (data) => {
  const response = await httpService.post(`${adminRoute}/add/admin`, data);
  return response;
};

export const updateAdminApi = async (id = '', data) => {
  const response = await httpService.put(`${adminRoute}/update/admin/${id}`, data);
  return response;
};

export const addUserGroupApi = async (data) => {
  const response = await httpService.post(`${adminRoute}/admin-user-group`, data);
  return response;
};

export const updateUserGroupApi = async (id = '', data) => {
  const response = await httpService.patch(`${adminRoute}/admin-user-group/${id}`, data);
  return response;
};

export const deleteAdminApi = async (id = '') => {
  const response = await httpService.delete(`${adminRoute}/remove/admin/${id}`);
  return response;
};

export const deleteMultipleAdminApi = async (ids = []) => {
  const response = await httpService.post(`${adminRoute}/remove/bulk/admin/`, { ids });
  return response;
};

export const deleteUserGroupApi = async (id = '') => {
  const response = await httpService.delete(`${adminRoute}/admin-user-group/${id}`);
  return response;
};

export const deleteMultipleUserGroupApi = async (ids = []) => {
  const response = await httpService.post(`${adminRoute}/admin-user-group/bulk/remove`, { ids });
  return response;
};
export const subscriptions = async () => {
  const response = await httpService.get(`${route}/subscription/history`);
  return response;
};

export const addSelfDevelopment = async (data) => {
  const response = await httpService.post(`${adminRoute}/self-development/add`, data);
  return response;
};

export const getAllSelfDevelopmentsCategory = async (id = '') => {
  const response = await httpService.get(`${adminRoute}/self-development/?category=${id}`);
  return response;
};

export const getAllSelfDevelopmentsCategories = async (id = '') => {
  const response = await httpService.get(`${adminRoute}/self-development/categories`);
  return response;
};

export const getSingleSelfDevelopment = async (id = '') => {
  const response = await httpService.get(`${adminRoute}/self-development/${id}`);
  return response;
};

export const updateSelfDevelopment = async (id = '') => {
  const response = await httpService.put(`${adminRoute}/self-development/update/${id}`);
  return response;
};

export const deleteSelfDevelopment = async (id = '') => {
  const response = await httpService.delete(`${adminRoute}/self-development/remove/${id}`);
  return response;
};
