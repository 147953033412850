import Button from "../../../components/UI/Button/Button";
import classes from "./Emails.module.css";
import Edit from "../../../assets/images/icons/edit.svg";
import Delete from "../../../assets/images/icons/delete.svg";
import Archive from "../../../assets/images/icons/archive.svg";
import { useNavigate } from "react-router";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useCallback, useEffect, useState } from "react";
import { errorHandler } from "../../../utils/errorHandler";
import FullScreenLoader from "../../../components/UI/FullScreenLoader/FullScreenLoader";
import ConfirmDialog from "../../../components/UI/ConfirmDialog/ConfirmDialog";
import CheckInput from "../../../components/UI/CheckInput/input";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "../../../redux/store";
import { getEmails } from "../../../redux/actions";
import { handleFilterItems } from "../../../utils";
import { deleteEmailApi, deleteMultipleEmailApi } from "../../../apis";

const Emails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isArchiveOpen, setIsArchiveOpen] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [unSelectedItems, setUnSelectedItems] = useState([]);
  const { isLoading, emails } = useSelector(store => store.email);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    sortField: null,
    sortOrder: null,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const onLazyLoad = useCallback((event) => {
    setLazyParams(event);
  }, []);

  const handleGetAllRecords = useCallback(() => {
    dispatch(getEmails(((lazyParams?.page || 0) + 1), lazyParams?.rows));
    setSelectedItem('');
  }, [dispatch, lazyParams?.page, lazyParams?.rows])

  const handleCheckAll = useCallback((e) => {
    setCheckAll(e.target.checked);
    setUnSelectedItems([])
    setSelectedItems([])
  }, []);

  const handleCheck = useCallback((item) => {
    if ((checkAll || selectedItems.find(s => s._id === item._id))) {
      if (!unSelectedItems.find(s => s._id === item._id)) {
        setUnSelectedItems(prev => [...prev, item]);
      }
      if (selectedItems.find(s => s._id === item._id)) {
        setSelectedItems(prev => prev.filter(p => p._id !== item._id));
      }
    }
    if ((!checkAll || unSelectedItems.find(s => s._id === item._id))) {
      if (unSelectedItems.find(s => s._id === item._id)) {
        setUnSelectedItems(prev => prev.filter(p => p._id !== item._id));
      }
      if (!selectedItems.find(s => s._id === item._id)) {
        setSelectedItems(prev => [...prev, item]);
      }
    }
  }, [checkAll, selectedItems, unSelectedItems]);

  const handleDeleteMultiple = useCallback(async () => {
    setLoading(true)
    try {
      if (checkAll || selectedItems.length > 0) {
        const items = checkAll ? handleFilterItems(emails?.records, unSelectedItems) : selectedItems;
        const { data } = await deleteMultipleEmailApi(items);
        setIsOpen(false)
        if (data) {
          toast.success(data.message || 'Records deleted successfully');
        }
      } else {
        toast.error("No record selected for this operation")
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [checkAll, selectedItems])

  const handleSelect = useCallback(async (item, isDeleteAction = true) => {
    setSelectedItem(item)
    if (isDeleteAction) {
      setIsOpen(true)
    } else {
      setIsArchiveOpen(true)
    }
  }, [])


  const handleDelete = useCallback(async () => {
    setLoading(true)
    try {
      if (selectedItem) {
        const { data } = await deleteEmailApi(selectedItem);
        setIsOpen(false)
        if (data) {
          toast.success('Record deleted successfully');
          setTimeout(() => {
            navigate(0)
          }, 500);
        }
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [navigate, selectedItem])

  const handleArchive = useCallback(async () => {
    setLoading(true)
    try {
      if (selectedItem) {
        // const { data } = await (selectedItem?.isArchived ? unArchiveEmailApi(selectedItem?._id) : archiveEmailApi(selectedItem?._id));
        setIsArchiveOpen(false)
        // if (data) {
        //   toast.success(data.message || `Record ${selectedItem?.isArchived ? "un" : ""}archived successfully`);
        //   handleGetAllRecords();
        // }
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [handleGetAllRecords, selectedItem])

  useEffect(() => {
    handleGetAllRecords();
  }, [handleGetAllRecords,])

  return (
    <div>
      {(isLoading || loading) && <FullScreenLoader />}

      <ConfirmDialog confirmText="Delete" handleConfirm={selectedItem ? handleDelete : handleDeleteMultiple} setIsOpen={setIsOpen} isOpen={isOpen} desc={`Are you sure you want to delete ${selectedItem ? 'this email' : 'these emails'}?`} />

      <ConfirmDialog confirmText={`${selectedItem?.isArchived ? "Una" : "A"}rchive`} handleConfirm={selectedItem ? handleArchive : null} setIsOpen={setIsArchiveOpen} isOpen={isArchiveOpen} desc={`Are you sure you want to ${selectedItem?.isArchived ? "un" : ""}archive ${selectedItem ? 'this email' : 'these emails'}?`} />

      <h2>Email Management</h2>
      <div className={`mt-md ${classes.table}`}>
        <div className={`flex flex-col md:flex-row md:items-center gap-4 mb-md ${classes.btn}`}>
          <div className={`flex items-center ${classes.otherbutton}`}>
            <Button variant="white">Activate</Button>
            <Button disabled={!checkAll && selectedItems.length === 0} onClick={() => setIsOpen(true)} variant="white">Delete</Button>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <input placeholder="Search" />
            <Button
              variant="yellow"
              onClick={() => navigate("/dashboard/add-email")}
            >
              Add Email
            </Button>
          </div>
        </div>

        <DataTable
          value={emails.records || []}
          lazy
          paginator={emails.totalPages > 1}
          rows={lazyParams.rows}
          totalRecords={emails.total}
          loading={isLoading}
          onPage={onLazyLoad}
          first={lazyParams.first}
          emptyMessage="No record found"
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '100%' }}
        >
          <Column className="pr-3 text-center"
            field="id"
            header={
              <CheckInput type="checkbox" className={`w-[18px] h-[18px] rounded-s`} isIndeterminate={checkAll && unSelectedItems.length > 0} checked={checkAll} onChange={handleCheckAll} />}
            body={(item) => (
              <div className="text-left">
                <CheckInput type="checkbox" className="w-[18px] h-[18px] rounded-s" checked={(checkAll || selectedItems.find(s => s._id === item._id)) && !unSelectedItems.includes(item)} onChange={() => handleCheck(item)} />
              </div>
            )
            }
          />
          <Column className="p-5 text-center"
            field="name"
            header="NAME"
            body={(item) => (
              <div className="text-left  min-w-[185px]">{item.name}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="subject"
            header="SUBJECT"
            body={(item) => (
              <div className=" min-w-[185px]">{item.subject}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="emailType"
            header="TYPE"
            body={(item) => (
              <div>{item.emailType}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="status"
            header="STATUS"
            body={(item) => (
              <div className="rounded-full bg-[#F5F6FA] h-[38px] w-[38px] flex items-center justify-center">
                {item.status === "Active" ? "A" : "I"}
              </div>)}
            sortable
          />
          <Column className="p-5" header="ACTIONS" body={(item) => (
            <div className="flex items-center gap-2 min-w-[120px]">
              <button className={`relative ${classes.edit}`}
                onClick={() => navigate("/dashboard/edit-email", { state: item })}>
                <img src={Edit} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Edit Email
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button onClick={() => handleSelect(item)} className={`relative ${classes.delete}`}>
                <img src={Delete} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Delete Email
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.archive}`} onClick={() => handleSelect(item, false)}>
                <img src={Archive} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Archive Email
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
            </div>)} />
        </DataTable>
      </div>
    </div>
  );
};

export default Emails;
