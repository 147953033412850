import { useEffect, useMemo } from "react";
import Button from "../../../components/UI/Button/Button";
import classes from "./Send-Email.module.css";
import { useDispatch, useSelector } from "../../../redux/store";
import { getEmails } from "../../../redux/actions";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { errorHandler } from "../../../utils";
import FullScreenLoader from "../../../components/UI/FullScreenLoader/FullScreenLoader";
import { sendEmailInternalApi } from "../../../apis";

const SendEmail = () => {
  const { emails, isLoading } = useSelector(store => store.email);
  const dispatch = useDispatch()
  const { state } = useLocation();
  const navigate = useNavigate();

  const recipients = useMemo(() => state?.recipients || [], [state])
  // console.log({ recipients })

  useEffect(() => {
    dispatch(getEmails(1, 100));
  }, [])

  return (
    <div>
      {(isLoading) && <FullScreenLoader />}

      <h2>Send Email</h2>
      <Formik
        initialValues={{
          templateId: state?.id || "",
          recipientIds: recipients?.map(recipient => recipient?._id) || [],
          emails: recipients?.map(recipient => recipient?.email) || [],
          subject: "",
          senderName: "",
          senderEmail: "",
          replyEmail: "",
          mergeFields: [],
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          try {
            const { data, message } = await sendEmailInternalApi({
              templateId: values.templateId,
              recipientIds: values.recipientIds,
              emailParams: {
                subject: values.subject,
                senderName: values.senderName,
                senderEmail: values.senderEmail,
                replyEmail: values.replyEmail,
              }
            });
            if (data) {
              toast.success(message || `Email sent successfully`);
              navigate(-1)
            }
          } catch (error) {
            errorHandler(error, Object.values(error.errors)[0])
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <label>
              Select Email*
              <select
                required
                name="templateId"
                {...getFieldProps("templateId")}
                onChange={e => {
                  handleChange(e);
                  setFieldValue('subject', emails?.records?.find(email => String(email._id) === e.target.value)?.subject);
                }}
                className="mt-sm"
              >
                <option value="">None</option>
                {(emails.records || [])?.map((email, i) => <option key={i} value={email?._id}>{email?.name}</option>)}
              </select>
            </label>
            <label>
              Email Subject
              <input type="text" {...getFieldProps("subject")} />
            </label>

            <div className={classes.mutiple}>
              <label>
                Sender Name
                <input type="text" {...getFieldProps("senderName")} />
              </label>
              <label>
                Sender Email Address
                <input type="text" {...getFieldProps("senderEmail")} />
              </label>
              <label>
                Reply to email (optional)
                <input type="text" {...getFieldProps("replyEmail")} />
              </label>
            </div>

            <label>
              Recipient(s)*
              <input required type="text" disabled className="!bg-transparent"
                // onChange={handleChange}
                {...getFieldProps("emails")} value={values.emails.join(", ")} />
            </label>

            <div className={classes.btn}>
              <Button variant="white" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button variant="yellow" type="submit" disabled={isSubmitting}>Send Email</Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SendEmail;
