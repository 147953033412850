import Button from "../../../components/UI/Button/Button";
import RadioInput from "../../../components/UI/RadioInput/input";
import classes from "./Add-Email.module.css";
import { useLocation, useNavigate } from "react-router";
import { Formik } from "formik";
import { errorHandler } from "../../../utils";
import { postAddEmailApi, postUpdateEmailApi } from "../../../apis";
import { toast } from "react-toastify";
import { Editor } from 'primereact/editor';


const AddEmail = ({ isUpdate = false }) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <h2>Email Management</h2>
      <Formik
        initialValues={{
          name: state?.name || "",
          emailType: state?.emailType || "",
          subject: state?.subject || "",
          senderEmail: state?.senderEmail || "",
          senderName: state?.senderName || "",
          replyEmail: state?.replyEmail || "",
          emailTemplate: state?.emailTemplate || "",
          status: state?.status || "Active",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          try {
            const { data } = isUpdate ? await postUpdateEmailApi(state._id, values) : await postAddEmailApi(values);
            if (data) {
              toast.success(data.message || `Email ${isUpdate ? "updated" : "added"} successfully`);
              navigate(-1)
            }
          } catch (error) {
            errorHandler(error, Object.values(error.errors)[0])
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
          isValid
        }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <label>
              Email Title*
              <input type="text" placeholder="Enter Email Title" required {...getFieldProps("name")} />
            </label>
            <label>
              Email Subject*
              <input type="text" placeholder="Enter Email Subject" required  {...getFieldProps("subject")} />
            </label>
            <div className={classes.container}>
              Email Type*
              <select {...getFieldProps("emailType")}>
                <option>Select Email type</option>
                <option>Admin</option>
                <option>Talent</option>
                <option>Client</option>
                <option>Marketing</option>
              </select>
            </div>
            <div className="flex items-center gap-5">
              <label>
                Sender Name*
                <input type="text" required  {...getFieldProps("senderName")} />
              </label>
              <label>
                Sender Email Address*
                <input type="email" required  {...getFieldProps("senderEmail")} />
              </label>
              <label>
                Reply Email(optional)
                <input type="email"  {...getFieldProps("replyEmail")} />
              </label>
            </div>
            <label className={classes.container}>
              Email Template*

              <Editor value={values.emailTemplate} onTextChange={(e) => setFieldValue('emailTemplate', e.htmlValue)} 
              // style={{ height: '320px' }} 
              className="mt-2" />
              {/* <textarea  {...getFieldProps("emailTemplate")}></textarea> */}
            </label>
            {/* <label>
              Email Fields*
              <input type="text" />
            </label> */}
            <div className={classes.radios}>
              <p>Status</p>
              <div>
                <RadioInput
                  text="Active"
                  value={true}
                  {...getFieldProps("status")}
                  checked={values.status === "Active"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setFieldValue('status', "Active");
                  }}
                />
                <RadioInput
                  text="Inactive"
                  value={false}
                  {...getFieldProps("status")}
                  checked={values.status === "Inactive"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setFieldValue('status', "Inactive");
                  }}
                />
              </div>
            </div>
            <div className={classes.btn}>
              <Button variant="white" isLink to="/dashboard/emails">
                Cancel
              </Button>
              <Button variant="blue" type="submit" disabled={isSubmitting || !isValid}>{isUpdate ? "Update" : "Add"}</Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddEmail;
