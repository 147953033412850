import classes from "./Jobs.module.css";
import Edit from "../../../../assets/images/icons/edit.svg";
import Delete from "../../../../assets/images/icons/delete.svg";
import Archive from "../../../../assets/images/icons/archive.svg";
import UnArchive from "../../../../assets/images/icons/unarchive.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dispatch, useSelector } from "../../../../redux/store";
import { getJobsByStatus } from "../../../../redux/actions/jobActions";
import FullScreenLoader from "../../../../components/UI/FullScreenLoader/FullScreenLoader";
import { capitalizeEachWord, errorHandler } from "../../../../utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { archiveJobApi, deleteJobApi, unArchiveJobApi } from "../../../../apis";
import { toast } from "react-toastify";
import ConfirmDialog from "../../../../components/UI/ConfirmDialog/ConfirmDialog";

const Jobs = () => {
  const { isLoading, jobs } = useSelector(store => store.job);

  const navigate = useNavigate();

  const jobStatus = useMemo(() => (['in-progress', 'closed', 'blocked', 'pending', 'approved']), [])
  const [currentPage, setCurrentPage] = useState("approved");

  const data = useMemo(() => jobs?.data, [jobs?.data])

  useEffect(() => {
    dispatch(getJobsByStatus(undefined, undefined, currentPage));
  }, [currentPage])






  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isArchiveOpen, setIsArchiveOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    sortField: null,
    sortOrder: null,
  });

  const handleGetAllRecords = useCallback(() => {
    dispatch(getJobsByStatus(((lazyParams?.page || 0) + 1), lazyParams?.rows, currentPage));
    setSelectedItem('');
  }, [currentPage, lazyParams?.page, lazyParams?.rows])

  const handleSelect = useCallback(async (item, isDeleteAction = true) => {
    setSelectedItem(item)
    if (isDeleteAction) {
      setIsOpen(true)
    } else {
      setIsArchiveOpen(true)
    }
  }, [])

  const handleDelete = useCallback(async () => {
    setLoading(true)
    try {
      if (selectedItem) {
        const { data } = await deleteJobApi(selectedItem);
        setIsOpen(false)
        if (data) {
          toast.success(data.message || 'Record deleted successfully');
          handleGetAllRecords()
        }
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [handleGetAllRecords, selectedItem])

  const handleArchive = useCallback(async () => {
    setLoading(true)
    try {
      if (selectedItem) {
        const { data } = await (selectedItem?.isArchived ? unArchiveJobApi(selectedItem?._id) : archiveJobApi(selectedItem?._id));
        setIsArchiveOpen(false)
        if (data) {
          toast.success(data.message || `Record ${selectedItem?.isArchived ? "un" : ""}archived successfully`);
          handleGetAllRecords();
        }
      }
    } catch (error) {
      errorHandler(error, error.errors ? Object.values(error.errors)[0] : '')
    }
    setLoading(false)
  }, [handleGetAllRecords, selectedItem])

  const onLazyLoad = useCallback((event) => {
    setLazyParams(event);
  }, [setLazyParams]);

  useEffect(() => {
    handleGetAllRecords()
  }, [handleGetAllRecords])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {(isLoading || loading) && <FullScreenLoader />}

      <ConfirmDialog confirmText="Delete" handleConfirm={selectedItem ? handleDelete : undefined} setIsOpen={setIsOpen} isOpen={isOpen} desc={`Are you sure you want to delete ${selectedItem ? 'this job' : 'these jobs'}?`} />

      <ConfirmDialog confirmText={`${selectedItem?.isArchived ? "Una" : "A"}rchive`} handleConfirm={selectedItem ? handleArchive : null} setIsOpen={setIsArchiveOpen} isOpen={isArchiveOpen} desc={`Are you sure you want to ${selectedItem?.isArchived ? "un" : ""}archive ${selectedItem ? 'this job' : 'these jobs'}?`} />

      <h2>Jobs</h2>
      <div className={`flex gap-4 justify-between mt-sm ${classes.btn}`}>
        <div className={`flex gap-2 flex-wrap flex-grow`}>
          {jobStatus?.map((item, i) => (
            <button
              key={`btn-${i}`}
              onClick={() => setCurrentPage(item)}
              className={currentPage === item ? classes.active : ""}
            >
              {capitalizeEachWord(item?.split("-")?.join(" "))} &nbsp; {item === currentPage ? jobs?.totalItems : ""}
            </button>
          ))}
          {/* <Button variant="yellow">Create Jobs</Button> */}
        </div>
        <input placeholder="Search" className="max-w-full w-[230px]" />
      </div>
      <div className={`mt-md ${classes.table}`}>

        <DataTable
          value={data || []}
          lazy
          paginator={jobs.totalPages > 1}
          rows={lazyParams.rows}
          totalRecords={jobs?.totalItems}
          loading={isLoading}
          onPage={onLazyLoad}
          first={lazyParams.first}
          emptyMessage="No record found"
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "100%" }}
          // tableStyle={{ minWidth: "100%", borderSpacing: "0 15px", borderCollapse: "separate", border: "red solid 1px" }}
          rowClassName=""
        >
          <Column className="p-5 text-center"
            field="title"
            header="TITLE"
            body={(item) => (
              <div className="text-left min-w-[150px]">
                <h3 className="font-semibold">{item?.title}</h3>
                <p className="">{item?.jobType}</p>
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="location"
            header="LOCATION"
            body={(item) => (
              <div className="min-w-[100px]">{item?.location || ""}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="type"
            header="TYPE"
            body={(item) => (
              <div >
                {item?.type}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="time"
            header="DATE"
            body={(item) => (
              <div className="min-w-[100px]">
                {item?.time}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="budget"
            header="SALARY"
            body={(item) => (
              <div >
                {item?.budget}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="applicants"
            header="CANDIDATE"
            body={(item) => (
              <div >
                {item?.applicants?.length || "0"}
              </div>)}
            sortable
          />
          <Column className="p-5"
            field="status"
            header="STATUS"
            body={(item) => (
              <div >
                {item?.status}
              </div>)}
            sortable
          />
          <Column className="p-5" header="ACTIONS" body={(item) => (
            <div className="flex items-center gap-2 min-w-[100px]">
              <button className={`relative ${classes.edit}`} onClick={() => navigate(`/dashboard/jobs/details`, { state: item })}>
                <img src={Edit} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Edit Job
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button onClick={() => handleSelect(item)} className={`relative ${classes.delete}`}>
                <img src={Delete} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    Delete Job
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
              <button className={`relative ${classes.archive}`} onClick={() => handleSelect(item, false)}>
                <img src={item?.isArchived ? UnArchive : Archive} alt="" />
                <div className={classes.hidden}>
                  <span className={`relative ${classes.description}`}>
                    {item?.isArchived ? "Una" : "A"}rchive Job
                    <span className={classes.arrowdown}></span>
                  </span>
                </div>
              </button>
            </div>)} />
        </DataTable>

        {/* <div className={classes.header}>
          <span>TITLE</span>
          <span>LOCATION</span>
          <span>TYPE</span>
          <span>DATE</span>
          <span>SALARY</span>
          <span>CANDIDATE</span>
          <span>ACTIONS</span>
        </div>
        <hr />
        {
          data?.length > 0 ?

            data?.map(
              (
                item,
                i
              ) => (
                <div
                  key={i}
                  className={classes.grid}
                >
                  <div>
                    <h3 className="font-semibold">{item?.title}</h3>
                    <p className="">{item?.jobType}</p>
                  </div>
                  <span>{item?.location}</span>
                  <span>{item?.duration}</span>
                  <span>{item?.time}</span>
                  <span>{item?.budget}</span>
                  <span>{item?.applicants?.length}</span>
                  <div>
                    <button onClick={() => navigate(`/dashboard/jobs/details`, { state: item })}>
                      <img src={Edit} alt="" />
                    </button>
                    <button>
                      <img src={Delete} alt="" />
                    </button>
                    <button>
                      <img src={Archive} alt="" />
                    </button>
                  </div>
                </div>
              )
            ) : (
              <p className="text-center py-4">No jobs found</p>
            )} */}
      </div>
    </div>
  );
};

export default Jobs;
