import classes from "./Header.module.css";
import Message from "../../../assets/images/icons/message.svg";
import { Bell, Logo, VideoIcon } from "../../../constants/svgs";
import { dispatch, useSelector } from "../../../redux/store";
import { useEffect } from "react";
import { getAdminData } from "../../../redux/actions/authActions";
import { NavLink } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

const Header = ({ side, setSide }) => {
  const { auth, profile } = useSelector(store => store.auth);
  const { activities } = useSelector(store => store.dashboard);

  useEffect(() => {
    if (!profile) {
      dispatch(getAdminData(auth.adminId));
    }
  }, [auth, profile])

  return (
    <header className={`flex ${classes.header}`}>
      <div
        className={`${classes.menu} ${side ? classes.cancel : ""}`}
        onClick={() => setSide(!side)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={classes.logo}>
        <Logo color="#030659" />
      </div>
      <div className={`flex ${classes.container} gap-5`}>
        <NavLink to="/dashboard/self-development" className="!bg-transparent !text-inherit !rounded-none">
          <VideoIcon />
        </NavLink>

        <Menu >
          <MenuButton className="inline-flex items-center">
            <Bell />
          </MenuButton>

          <MenuItems
            transition
            anchor="bottom start"
            className="w-[448px] max-w-full origin-top-right rounded-xl border p-4 py-2 text-sm bg-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            {[...(activities?.activities || [])].slice(0, 5)?.map((activity, i) => <MenuItem key={i}>
              <span className="group flex w-full items-center gap-2 py-3 data-[focus]:bg-white/10 text-sm font-light border-b">
                {activity?.message}
              </span>
            </MenuItem>)}
            <MenuItem>
              <NavLink to="/dashboard/overview/#activity" className="group flex w-fit items-center gap-2 py-3 active:!text-[#030659] focus:!text-[#030659] !bg-transparent !text-[#030659] ml-auto">
                <span className="!text-[#030659]">See all</span>
              </NavLink>
            </MenuItem>
          </MenuItems>
        </Menu>

        <img src={Message} alt="Message" />
        <span className={classes.line}></span>
        <div className={`${classes.company}`}>
          <span className={`center-flex ${classes.checkContainer}`}>
            <span className={classes.check}></span>
          </span>
          <span className="">{profile?.email || "- -"}</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
