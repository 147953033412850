import { adminRoute } from "../base";
import httpService from "../base/httpService";

export const getDashboardMetricsApi = async (data) => {
  const response = await httpService.get(`${adminRoute}/dashboard`);
  return response;
};

export const getDashboardActivityApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`api/activity/admin/feed?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

