import { adminRoute } from "../base";
import httpService from "../base/httpService";

export const getJobsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${adminRoute}/all-jobs?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const getJobsByStatusApi = async (page = 1, limit = 10, query = '', order = 'desc') => {
  const response = await httpService.post(`${adminRoute}/jobs/search`, {
    page,
    limit,
    order,
    query,
    fields: ["jobStatus"],
    sort: "createdAt",
  });
  return response;
};

export const updateJobApi = async (id = '', data) => {
  const response = await httpService.put(`${adminRoute}/jobs/update/${id}`, data);
  return response;
};

export const deleteJobApi = async (id = '') => {
  const response = await httpService.delete(`${adminRoute}/jobs/delete/${id}`);
  return response;
};

export const archiveJobApi = async (id = '') => {
  const response = await httpService.post(`${adminRoute}/jobs/archive/${id}`);
  return response;
};

export const unArchiveJobApi = async (id = '') => {
  const response = await httpService.post(`${adminRoute}/jobs/unarchive/${id}`);
  return response;
};
