/* eslint-disable eqeqeq */
import Button from "../../../../../components/UI/Button/Button";
import RadioInput from "../../../../../components/UI/RadioInput/input";
import classes from "./index.module.css";
import { useLocation, useNavigate } from "react-router";
import { Formik } from "formik";
import { updateClientApi } from "../../../../../apis";
import { toast } from "react-toastify";
import { errorHandler } from "../../../../../utils/errorHandler";
import TagsInput from "react-tagsinput";

export const companyType = [
  {
    text: "Individual",
    img: (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9863 4C14.0472 4 15.0646 4.42143 15.8148 5.17157C16.5649 5.92172 16.9863 6.93913 16.9863 8C16.9863 9.06086 16.5649 10.0783 15.8148 10.8284C15.0646 11.5786 14.0472 12 12.9863 12C11.9255 12 10.908 11.5786 10.1579 10.8284C9.40775 10.0783 8.98633 9.06086 8.98633 8C8.98633 6.93913 9.40775 5.92172 10.1579 5.17157C10.908 4.42143 11.9255 4 12.9863 4ZM12.9863 6C12.4559 6 11.9472 6.21071 11.5721 6.58579C11.197 6.96086 10.9863 7.46957 10.9863 8C10.9863 8.53043 11.197 9.03914 11.5721 9.41421C11.9472 9.78928 12.4559 10 12.9863 10C13.5168 10 14.0255 9.78928 14.4005 9.41421C14.7756 9.03914 14.9863 8.53043 14.9863 8C14.9863 7.46957 14.7756 6.96086 14.4005 6.58579C14.0255 6.21071 13.5168 6 12.9863 6ZM12.9863 13C15.6563 13 20.9863 14.33 20.9863 17V20H4.98633V17C4.98633 14.33 10.3163 13 12.9863 13ZM12.9863 14.9C10.0163 14.9 6.88633 16.36 6.88633 17V18.1H19.0863V17C19.0863 16.36 15.9563 14.9 12.9863 14.9Z"
          fill="#030659"
        />
      </svg>
    ),
  },
  {
    text: "Company",
    img: (
      <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.1729 2.28718V18.5696H10.1729V15.4035H8.17285V18.5696H2.17285V2.28718H16.1729ZM12.1729 5.90549H14.1729V4.09633H12.1729V5.90549ZM8.17285 5.90549H10.1729V4.09633H8.17285V5.90549ZM4.17285 5.90549H6.17285V4.09633H4.17285V5.90549ZM12.1729 9.52379H14.1729V7.71464H12.1729V9.52379ZM8.17285 9.52379H10.1729V7.71464H8.17285V9.52379ZM4.17285 9.52379H6.17285V7.71464H4.17285V9.52379ZM12.1729 13.1421H14.1729V11.3329H12.1729V13.1421ZM8.17285 13.1421H10.1729V11.3329H8.17285V13.1421ZM4.17285 13.1421H6.17285V11.3329H4.17285V13.1421ZM12.1729 16.7604H14.1729V14.9513H12.1729V16.7604ZM4.17285 16.7604H6.17285V14.9513H4.17285V16.7604ZM18.1729 0.478027H0.172852V20.3787H18.1729V0.478027Z" fill="#030659" />
      </svg>

    ),
  },
];

const AddNewClient = ({ title = "Add" }) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <h2>{title} Client</h2>
      <Formik
        initialValues={{
          firstName: state?.firstName || "",
          lastName: state?.lastName || "",
          email: state?.email || "",
          phone: state?.phone || "",
          companyName: state?.companyName || "",
          companyType: state?.companyType || "",
          companySize: state?.companySize || 0,
          status: state?.status || "Active",
          linkedInUrl: state?.linkedInUrl || "",
          industry: state?.industry || [],
          employeeIdentificationNumber: state?.employeeIdentificationNumber || "",
          isVerified: !!state?.isVerified,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          try {
            // if (state?.isVerified === true) {
            //   delete values.email;
            // }
            const { data } = await updateClientApi(state._id, values);
            if (data) {
              toast.success('Update was successful');
              navigate(-1)
            }
          } catch (error) {
            errorHandler(error, Object.values(error.errors)[0])
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className={classes.container}>
            <div className={classes["form-group"]}>
              <div className={`relative ${classes.avatar} mb-5`}>
                {state?.clientLogo ? (
                  <img src={state.clientLogo} alt="" />
                ) : (
                  <span className="text-white bg-[#04097B] flex items-center justify-center">
                    {state?.firstName
                      ? state.firstName?.split("")[0]?.toUpperCase() +
                      "." +
                      state.lastName?.split("")[0]?.toUpperCase()
                      : ""}
                  </span>
                )}
              </div>
            </div>
            <div className={classes["form-group"]}>
              <label>
                First Name*
                <input required type="text" placeholder="Jane" className="mt-sm" {...getFieldProps("firstName")} />
              </label>
              <label>
                Last Name*
                <input required type="text" placeholder="Jane" className="mt-sm" {...getFieldProps("lastName")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Company Name*
                <input required type="text" placeholder="Awasource" className="mt-sm" {...getFieldProps("companyName")} />
              </label>
              <label>
                Work Email*
                <input required type="email" placeholder="Janedoe@awasource.com" className="mt-sm" {...getFieldProps("email")} disabled={state?.isVerified === true} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Position*
                <select required name="emailType" onChange={handleChange} className="mt-sm" {...getFieldProps("position")}>
                  <option>None</option>
                  <option>Project Manager</option>
                </select>
              </label>
              <label>
                Phone Number*
                <input required type="text" placeholder="Phone Number" className="mt-sm" {...getFieldProps("phone")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Facebook Url
                <input type="text" placeholder="Facebook Url" className="mt-sm" {...getFieldProps("facebookUrl")} />
              </label>
              <label>
                Twitter Url
                <input type="text" placeholder="Twitter Url" className="mt-sm" {...getFieldProps("twitterUrl")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                LinkedIn Url
                <input type="text" placeholder="LinkedIn Url" className="mt-sm" {...getFieldProps("linkedInUrl")} />
              </label>
              <label>
                Instagram Url
                <input type="text" placeholder="Instagram Url" className="mt-sm" {...getFieldProps("instagramUrl")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label className="col-span-2">
                Summary
                <textarea placeholder="I am lorem ipsum etc etc" className="mt-sm" {...getFieldProps("summary")} rows={5} />
              </label>
            </div>

            <hr className="my-10" />
            <h5 className="text-[#152238] font-semibold text-xl mb-5">Company Type</h5>

            <div className={classes["form-group"]}>
              <label className="col-span-2">
                <span className="flex gap-5 justify-between items-center">Company size
                </span>
                <div className={`flex relative mt-sm`}>
                  {values?.companySize == "0" && (
                    <aside className={classes.rating}>1 - 10 employees</aside>
                  )}
                  {values?.companySize == "1" && (
                    <aside className={classes.rating}>10 - 50 employees</aside>
                  )}
                  {values?.companySize == "2" && (
                    <aside className={classes.rating}>50 - 200 employees</aside>
                  )}
                  {values?.companySize == "3" && (
                    <aside className={classes.rating}>200 - 500 employees</aside>
                  )}
                  {values?.companySize == "4" && (
                    <aside className={classes.rating}>1k - 2k employees</aside>
                  )}
                  {values?.companySize == "5" && (
                    <aside className={classes.rating}>2k - 10k employees</aside>
                  )}
                  {values?.companySize == "6" && (
                    <aside className={classes.rating}>10k - 50k employees</aside>
                  )}
                  {values?.companySize == "7" && (
                    <aside className={classes.rating}>50k - 100k employees</aside>
                  )}
                  {values?.companySize == "8" && (
                    <aside className={classes.rating}>100k+ employees</aside>
                  )}

                  <div className={`relative ${classes.rangeParent}`}>
                    <span
                      className={`${classes.track} ${values?.companySize > 1 ? classes.blue : classes.yellow
                        }`}
                      style={{ width: `${values?.companySize * 12.5}%` }}
                    ></span>
                    <aside className={classes.dot1}></aside>
                    <aside className={classes.dot2}></aside>
                    <aside className={classes.dot3}></aside>
                    <aside className={classes.dot4}></aside>
                    <aside className={classes.dot5}></aside>
                    <aside className={classes.dot6}></aside>
                    <aside className={classes.dot7}></aside>
                    <aside className={classes.dot8}></aside>
                    <input
                      type="range"
                      min="0"
                      max="8"
                      step="1"
                      defaultValue={values?.companySize}
                      value={values?.companySize}
                      onChange={(e) => setFieldValue('companySize', e.target.value)}
                      className={classes.slider}
                    />
                  </div>
                </div>
              </label>
            </div>

            <div className={`mt-md ${classes.skills}`}>
              <p>Industry</p>
              <TagsInput className="mt-sm" required value={values.industry} onChange={(e) => setFieldValue('industry', e)} addKeys={[188, 9, 13]} />
            </div>

            <div className={classes["form-group"]}>
              <label className="col-span-2">
                Company type
                <div className={`mt-5 flex items-center gap-5 md:gap-16`}>
                  {companyType.map((item, index) => (
                    <div
                      className={`flex flex-col items-center justify-center gap-4 px-8 py-5 rounded-[5px] border border-[#030659] bg-[#F5F6FA]  ${values.companyType === item.text ? "" : "opacity-35"
                        }`}
                      key={index}
                      onClick={() => setFieldValue('companyType', item.text)}
                    >
                      {item.img}
                      <p>{item.text}</p>
                    </div>
                  ))}
                </div>
              </label>
            </div>

            <hr className="my-10" />
            <h5 className="text-[#152238] font-semibold text-xl mb-5">Company Information</h5>

            <div className={classes["form-group"]}>
              <label>
                Year of Establishment
                <input type="date" placeholder="DD/MM/YYYY" className="mt-sm" {...getFieldProps("year")} />
              </label>
              <label>
                Company Website Url
                <input type="text" placeholder="website url" className="mt-sm" {...getFieldProps("website")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label className="col-span-2">
                Company Address
                <input type="text" placeholder="Company address" className="mt-sm" {...getFieldProps("address")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                CAC Number*
                <input type="text" placeholder="CAC number" className="mt-sm" {...getFieldProps("cacNumber")} />
              </label>
              <label>
                Driver's License Number*
                <input type="text" placeholder="Driver's license number" className="mt-sm" {...getFieldProps("license")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                National Identification Number*
                <input type="text" placeholder="National Identification Number" className="mt-sm" {...getFieldProps("nin")} />
              </label>
              <label>
                International Passport Number*
                <input type="text" placeholder="International passport number" className="mt-sm" {...getFieldProps("passport")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Employee Identification Number*
                <input type="text" placeholder="Employee Identification number" className="mt-sm" {...getFieldProps("employeeIdentificationNumber")} />
              </label>
              <label>
                Tax Identification Number*
                <input type="text" placeholder="Tax Identification Number" className="mt-sm" {...getFieldProps("tin")} />
              </label>
            </div>
            <div className={classes["form-group"]}>
              <label>
                Verification Status
                <div className="mt-sm">
                  <RadioInput
                    text="Verified"
                    value={true}
                    name="isVerified"
                    checked={values.isVerified === true}
                    onChange={(e) => {
                      if (e.target.checked)
                        setFieldValue('isVerified', e.target.value);
                    }}
                  />

                  <RadioInput
                    text="Unverified"
                    value={false}
                    name="isVerified"
                    checked={values.isVerified === false}
                    onChange={(e) => {
                      if (e.target.checked)
                        setFieldValue('isVerified', e.target.value);
                    }}
                  />
                </div>
              </label>
            </div>

            {/* <div className={classes["form-group"]}>
              <label>
                Password
                <input type="text" placeholder="Password" className="mt-sm" {...getFieldProps("password")} />
              </label>
              <label>
                Confirm Password
                <input type="text" placeholder="Confirm Password" className="mt-sm" {...getFieldProps("confirm_password")} />
              </label>
            </div> */}

            <div className={classes.btn}>
              <Button variant="white" isLink to="/dashboard/users/clients">
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting} variant="yellow">Save</Button>
              <Button type="submit" disabled={isSubmitting} variant="blue">Save and notify</Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddNewClient;
