import { dashboardSlice as slice } from '../slices/dashboardSlice';
import { dispatch } from '../store';
import { getDashboardActivityApi, getDashboardMetricsApi } from '../../apis';
import { errorHandler } from '../../utils/errorHandler';

export function getMetrics() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await getDashboardMetricsApi();

      dispatch(slice.actions.getMetricsSuccess(data));
    } catch (err) {
      errorHandler(err, "Failed to get metrics!", false);
      dispatch(slice.actions.hasError(err));
    }
  };
}

export function getActivity(page = 1, limit = 10) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await getDashboardActivityApi(page, limit);

      dispatch(slice.actions.getActivitiesSuccess(data));
    } catch (err) {
      errorHandler(err, "Failed to get activity!", false);
      dispatch(slice.actions.hasError(err));
    }
  };
}
