export const Sort = () => (
	<svg
		width="18"
		height="24"
		viewBox="0 0 18 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.86076 9.60155L8.95368 6.92989L12.0466 9.60155L12.9967 8.77905L8.95368 5.27905L4.91064 8.77905L5.86076 9.60155Z"
			fill="black"
		/>
		<path
			d="M5.86076 14.6172L8.95368 17.2947L12.0466 14.6172L12.9967 15.4455L8.95368 18.9455L4.91064 15.4455L5.86076 14.6172Z"
			fill="black"
		/>
	</svg>
);
