import { adminRoute } from "../base";
import httpService from "../base/httpService";

export const getEmailsApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`${adminRoute}/email?page=${page}&limit=${limit}&order=${order}`);
  return response;
};

export const getCountriesApi = async (page = 1, limit = 10, order = 'desc') => {
  const response = await httpService.get(`https://countries-states.vercel.app/api/countries`);
  return response;
};

export const getSingleEmailApi = async (id = '', data) => {
  const response = await httpService.post(`${adminRoute}/email/preview/${id}`, data);
  return response;
};

export const postAddEmailApi = async (data) => {
  const response = await httpService.post(`${adminRoute}/email/add`, data);
  return response;
};

export const postUpdateEmailApi = async (id = '', data) => {
  const response = await httpService.post(`${adminRoute}/email/update/${id}`, data);
  return response;
};

export const deleteEmailApi = async (id = '') => {
  const response = await httpService.delete(`${adminRoute}/email/delete/${id}`);
  return response;
};

export const deleteMultipleEmailApi = async (ids = []) => {
  const response = await httpService.post(`${adminRoute}/email/delete/bulk`, { ids });
  return response;
};

export const sendEmailInternalApi = async (data) => {
  const response = await httpService.post(`${adminRoute}/email/send/internal`, data);
  return response;
};

export const sendEmailExternalApi = async (data) => {
  const response = await httpService.post(`${adminRoute}/email/send/external`, data);
  return response;
};
