import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  error: {},
  metrics: {
    clients: 0,
    jobs: 0,
    talents: 0,
  },
  activities: {
    activities: [],
    totalCount: 0,
    page: 1,
    limit: 10,
    totalPages: 1
  }
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getMetricsSuccess(state, action) {
      state.isLoading = false;
      state.metrics = action.payload;
    },
    getActivitiesSuccess(state, action) {
      const { activities = [], ...others } = action.payload;

      state.isLoading = false;
      state.activities = { ...others, activities: [...state.activities.activities, ...activities] };
    },
  },
});

export default dashboardSlice.reducer;
